import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PAGE_TITLE = "How to Help Us"
const PAGE_NAME = "howToHelp"
const HowToHelpPage = props => {
  return (
    <Layout pageName={PAGE_NAME} headerTitle={PAGE_TITLE}>
      <SEO title={PAGE_TITLE} />
      <section id="about-us" className="container">
        <div className="row">
          <div className="col-sm-12">
            <ul>
              <li>
                <b>Donate:</b> Any single dollar or Euro will be helpful to
                support this humanitarian works, Please click on our Paypal
                account to transfer your blessed donation.
              </li>
              <li>
                <b>Recommend:</b> Your recommendation to your good contacts may
                help us to reachout and mobilize many supports to our works. You
                may refer us to any donors and NGOs that would like to partner
                with us.
              </li>
              <li>
                <b>Invest:</b> There are Young people with good business ideas
                but they don't means to start their business, so we call for
                investors to work with us.
              </li>
              <li>
                <b>Sponsoring:</b> We are calling good people or donors to
                sponsor children in education and health through a Foster care
                initiative. Contact us for more details.
              </li>
              <li>
                <b>Volunteer:</b> We are calling for volunteers from abroad to
                help us to implement projects (ongoing and upcoming) business
                mentorship, child protection, training, peacebuilding,
                fundraising, project management, financial, and sports training.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HowToHelpPage
